import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import "./home.scss";
import Widget from "../../components/widget/Widget";
import Table from "../../components/table/Table";
import {useDispatch, useSelector} from "react-redux";
import React, {useContext, useEffect} from "react";
import SocketContext from "../../store/SocketContext";
import {setData} from "../../store/dataSlice";
import Grid from "@mui/material/Unstable_Grid2";
import {Link} from "react-router-dom";

const Home = () => {

  const data = useSelector((state) => state.data);
  const dispatch = useDispatch();
  const socket = useContext(SocketContext)

  useEffect( () => {
    if (socket) {
      socket.emit(`getShift`);
      socket.on(`setShift`, (initialData) => {
        dispatch(setData(initialData));
        console.log("initialData")
      });
    }
  }, [dispatch, socket]);

    const handleClick = () => {
      console.log('Button clicked!');
      socket.emit(`generateShift`);
      socket.on(`generationResult`, (res) => {
        console.log("res-".repeat(10))
        console.log(res);
      });
      if (socket) {
        socket.emit(`getShift`);
        socket.on(`setShift`, (initialData) => {
          dispatch(setData(initialData));
          console.log("initialData")
        });
      }
    };

  return (
    <div className="home">
      <Sidebar />
      <div className="homeContainer">
        <Navbar />
        <div className="widgets">
          <Widget type="dealer" />
          <Widget type="game" />
          <Widget type="table" />
        </div>

        <div className="listContainer">
          <Grid xs={12}>
            <div align="right">
              <button className="button" onClick={handleClick}>
                Generate
              </button>
            </div>
          </Grid>
          <div className="listTitle">Daily Shift</div>
          <Table title={'Home'} item={data}/>
        </div>
      </div>
    </div>
  );
};

export default Home;
