import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { createRoot } from 'react-dom/client';

import { DarkModeContextProvider } from "./context/darkModeContext";

ReactDOM.render(
    <React.StrictMode>
        <DarkModeContextProvider>
            <App />
        </DarkModeContextProvider>
    </React.StrictMode>,
    document.getElementById("root")
);

// // Use createRoot to render your app
// const rootElement = document.getElementById('root');
// const root = createRoot(rootElement);
// root.render(<App />);