import { createSlice } from '@reduxjs/toolkit';

const dataSlice = createSlice({
  name: 'data',
  initialState: {
    data: [],
    error: {
      status: false,
      message: ""
    },
    success: {
      status: false
    }
  },
  reducers: {
    setData(state, action) {
      state.success.status = false;
      state.data = action.payload;
    },
    updateData(state, action) {
      const updatedData = action.payload;
      // Update the specific item in the state array
      const index = state.findIndex((item) => item.id === updatedData.id);
      if (index !== -1) {
        state[index] = updatedData;
      }
    },
    setError(state, action) {
      state.success.status = false;
      state.error.status = true;
      state.error.message = action.payload;
    },
    setSuccess(state, action) {
      state.success.status = true;
      state.error.status = false;
    }
  },
});

export const { setData, updateData, setError, setSuccess } = dataSlice.actions;

export default dataSlice.reducer;