import "./single.scss";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import {useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import React, {useCallback, useContext, useEffect, useState} from "react";
import SocketContext from "../../store/SocketContext";
import {setData} from "../../store/dataSlice";
import {gameFields, tableFields, userFields} from "../../constants/formSource";
import Grid from "@mui/material/Unstable_Grid2";
import Table from "../../components/table/Table";
import { Select, MenuItem} from '@mui/material';
import { useMemo } from "react";

const fieldMap = {
  Table: tableFields,
  Game: gameFields,
  Dealer: userFields,
};

const Single = ({title}) => {
 
  const fields = fieldMap[title] || [];
  const { itemId } = useParams();
  const storeData = useSelector((state) => state.data);
  const dispatch = useDispatch();
  const socket = useContext(SocketContext);
  const [selectedValues, setSelectedValues] = React.useState([]);
  const item = storeData.data.dealer;

  const [formData, setFormData] = useState({
    name: '',
    status: '',
    game_id: '',
    phone: '',
    email: '',
    selectedGames: [],
  });

  useEffect( () => {
    if (socket) {
      socket.emit(`get${title}`, itemId);

      socket.on(`set${title}`, (initialData) => {
        dispatch(setData(initialData))
        // Set the initial values in the form state

        const dealer = initialData?.dealer;
        console.log("dealer ", dealer)
        const selectedGames = dealer?.games.reduce((current, item) => {
          current.push(parseInt(item.id))
          return current;
        }, []);
        
        setFormData({
          name: dealer?.name,
          status: dealer?.status,
          game_id: dealer?.game_id,
          phone: dealer?.phone,
          email: dealer?.email,
          selectedGames
        });
      });
    }
  },  [socket, title]);

  const handleFormSubmit = async (event) => {
    event.preventDefault();
    const data = {
        'id':itemId,
        'values':formData,
    }
    socket.emit(`update${title}`, data);
    socket.emit(`get${title}`, itemId);

    // Clean up the event listener when the component unmounts
    return () => {
      socket.off(`get${title}`);
    };
  };

  const handleInputChange = useCallback(async (event) => {
    const { name, value } = event.target;
    console.log("{ name, value } ", { name, value })
    setFormData((formData) => ({
      ...formData,
      [name]: value,
    }));
  }, []);

  // const isOneElement = 'name' in item
  const isOneElement = item?.hasOwnProperty('name');
  const gamesList = storeData.data.games ?? [];
  const dealerGames = storeData.data?.dealer?.games ?? [];
  
  const selectedGames = useMemo(() => {
    return dealerGames.reduce((current, item) => {
      current.push(parseInt(item.id))
      return current;
    }, []);
  }, [dealerGames])
  useEffect(() => {
    setSelectedValues(selectedGames)
  }, [selectedGames])

  const handleChange = (event) => {
    const { value } = event.target;
    console.log("value ", value)
    setSelectedValues(value);
  };
  
  let games = [];
  if (title === 'Dealer' && socket) {
    socket.on(`setGames`, (initialData) => {
      games = initialData
    });
  }

  console.log("formData?.categories ", formData)
  return (
    <div className="single">
      <Sidebar />
      <div className="singleContainer">
        <Navbar />
        <div className="top">
          <div className="left">
            <h1 className="title">{title} Information</h1>
            <div className="item">
              <img
                src="https://images.pexels.com/photos/733872/pexels-photo-733872.jpeg?auto=compress&cs=tinysrgb&dpr=3&h=750&w=1260"
                alt=""
                className="itemImg"
              />

              <div className="details">

                {isOneElement ? (
                    <>
                      <h1 className="itemTitle">{item.name}</h1>
                      {Object.entries(item).map(([key, value], index) => (
                          <div className="detailItem" key={index}>
                            <span className="itemKey">{key}:</span>
                            <span className="itemValue">{typeof value === 'object' ? value?.name : value}</span>
                          </div>
                      ))}
                    </>
                ): (
                    <h1>Empty item!</h1>
                )}

              </div>
            </div>
          </div>
          <div className="right">
            <Grid container>
              <Grid xs={6} xsOffset={3}>
              {item?.error?.status && <Grid className="error-wrapper">
                  <p>{item?.error?.message}</p>  
                </Grid>}
                {item?.success?.status && <Grid className="success-wrapper">
                  <p>You have successfully saved</p>  
                </Grid>}
                <form onSubmit={handleFormSubmit}>
                  <Grid xs={12}>
                    {fields.map((field) => (
                        <div className="formInput" key={field.id}>
                          <label>{field.label}</label>
                          <input
                              type={field.type}
                              name={field.name}
                              onChange={handleInputChange}
                              placeholder={field.placeholder}
                              value={formData[field.name]}
                          />
                        </div>
                    ))}

                    {title === 'Dealer' ? (
                        <div className="formInput">
                          <label>Select</label>
                          <Select multiple name="selectedGames" value={formData?.selectedGames} onChange={handleInputChange}>
                            {gamesList.map((current) => {
                              return (
                                <MenuItem value={current.id} key={current.id+current.name}>{current.name}</MenuItem>
                              )
                            })}
                            {/* <MenuItem value="option1">Option 1</MenuItem>
                            <MenuItem value="option2">Option 2</MenuItem>
                            <MenuItem value="option3">Option 3</MenuItem> */}
                          </Select>
                        </div>
                  ): (
                    <>
                    </>
                  )}

                  </Grid>

                  <Grid xs={12}>
                    <div align="center">
                      <button type="submit">Send</button>
                    </div>
                  </Grid>
                </form>

              </Grid>
            </Grid>
          </div>
        </div>

        <div className="bottom">
          {isOneElement ? (
              <>
                <Table title={title} item={item}/>
              </>
          ):(
              <>
              </>
          )}

        </div>
      </div>
    </div>
  );
};

export default Single;
