import React, { useState } from 'react';
import { Button, Popover, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';

const PopConfirm = ({ title, content, onConfirm, onCancel, children }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleConfirm = () => {
    onConfirm();
    handleClose();
  };

  const handleCancel = () => {
    // onCancel();
    handleClose();
  };

  return (
    <>
      <Button variant="outlined" onClick={handleClick}>
        {children}
      </Button>
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <div>
          <DialogTitle>{title}</DialogTitle>
          <DialogContent>{content}</DialogContent>
          <DialogActions>
            <Button variant="outlined" onClick={handleConfirm}>
              Confirm
            </Button>
            <Button variant="outlined" onClick={handleCancel}>
              Cancel
            </Button>
          </DialogActions>
        </div>
      </Popover>
    </>
  );
};

export default PopConfirm;
