import React, { useState, useEffect } from 'react';
import SocketContext from './SocketContext';
import io from 'socket.io-client';
import { useDispatch } from 'react-redux';
import { setError, setSuccess } from './dataSlice';

const SocketProvider = ({ children }) => {
  const [socket, setSocket] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    const apiURL = process.env.REACT_APP_API_URL;

    const newSocket = io(`${apiURL}`, {
        transports: ["websocket", "polling", "flashsocket"],
        // auth: {
        //     token: token,
        // },
    });
    newSocket.on("error", (data) => {
      dispatch(setError(data))
    });
    newSocket.on("success", () => {
      dispatch(setSuccess())
    })
    setSocket(newSocket);

    return () => newSocket.close();
  }, []);

  return (
    <SocketContext.Provider value={socket}>
      {children}
    </SocketContext.Provider>
  );
};

export default SocketProvider;