import "./table.scss";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import React from "react";
import moment from "moment/moment";
import {Link} from "react-router-dom";
// const moment = require('moment');


const List = (data) => {
  const home = Object.keys(data).length === 0;
  let rows = [];
  if (home) {
    rows = [
      {
        id: 1143155,
        product: "Acer Nitro 5",
        img: "https://m.media-amazon.com/images/I/81bc8mA3nKL._AC_UY327_FMwebp_QL65_.jpg",
        customer: "John Smith",
        date: "1 March",
        amount: 785,
        method: "Cash on Delivery",
        status: "Reject",
      },
      {
        id: 2235235,
        product: "Playstation 5",
        img: "https://m.media-amazon.com/images/I/31JaiPXYI8L._AC_UY327_FMwebp_QL65_.jpg",
        customer: "Michael Doe",
        date: "1 March",
        amount: 900,
        method: "Online Payment",
        status: "Pending",
      },
      {
        id: 2342353,
        product: "Redragon S101",
        img: "https://m.media-amazon.com/images/I/71kr3WAj1FL._AC_UY327_FMwebp_QL65_.jpg",
        customer: "John Smith",
        date: "1 March",
        amount: 35,
        method: "Cash on Delivery",
        status: "Pending",
      },
      {
        id: 2357741,
        product: "Razer Blade 15",
        img: "https://m.media-amazon.com/images/I/71wF7YDIQkL._AC_UY327_FMwebp_QL65_.jpg",
        customer: "Jane Smith",
        date: "1 March",
        amount: 920,
        method: "Online",
        status: "Approved",
      },
      {
        id: 2342355,
        product: "ASUS ROG Strix",
        img: "https://m.media-amazon.com/images/I/81hH5vK-MCL._AC_UY327_FMwebp_QL65_.jpg",
        customer: "Harold Carol",
        date: "1 March",
        amount: 2000,
        method: "Online",
        status: "Pending",
      },
    ];

  } else {
      if (data?.title === 'Table') {
        rows = [data.item?.game]
      } else if (data?.title === 'Dealer') {
        rows = data?.item?.games
      } else if (data?.title === 'Home') {
        rows = data?.item?.data
        console.log(data)
      }
  }

  return (
    <TableContainer component={Paper} className="table">
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            {home ? (
                <>
                  <TableCell className="tableCell">Tracking ID</TableCell>
                  <TableCell className="tableCell">Product</TableCell>
                  <TableCell className="tableCell">Customer</TableCell>
                  <TableCell className="tableCell">Date</TableCell>
                  <TableCell className="tableCell">Amount</TableCell>
                  <TableCell className="tableCell">Payment Method</TableCell>
                  <TableCell className="tableCell">Status</TableCell>
                </>
            ) : (
                <>
                  {rows && rows[0] ? (
                      <>
                        {Object.entries(rows[0]).map(([key, value], index) => (
                            <TableCell className="tableCell" key={index}>
                              {key}
                            </TableCell>
                        ))}
                      </>
                  ) : (
                      <TableCell className="tableCell">No data available</TableCell>
                  )}
                </>
            )}
            <TableCell className="tableCell">Action</TableCell>

          </TableRow>
        </TableHead>
        <TableBody>
          {
            rows
                .filter((row) => row)
                .map((row, index) => {

                  console.log(row, index)
                return (
              <TableRow key={index}>
                { home ? (
                    <>
                      <TableCell className="tableCell">{row.id}</TableCell>
                      <TableCell className="tableCell">
                        <div className="cellWrapper">
                          <img src={row.img} alt="" className="image" />
                          {row.product}
                        </div>
                      </TableCell>
                      <TableCell className="tableCell">{row.customer}</TableCell>
                      <TableCell className="tableCell">{row.date}</TableCell>
                      <TableCell className="tableCell">{row.amount}</TableCell>
                      <TableCell className="tableCell">{row.method}</TableCell>
                      <TableCell className="tableCell">
                        <span className={`status ${row.status}`}>{row.status}</span>
                      </TableCell>
                    </>
                ) : (
                    <>
                      {Object.entries(row).map(([key, value], index) => {
                        return (
                          <TableCell className="tableCell" key={index}>
                            {typeof value === 'object' ? value?.name :
                              (
                                key === 'createdAt'
                                || key === 'updatedAt'
                                || key === 'date'
                              ) ?
                                moment(value).format('YYYY-MM-DD HH:mm:ss')
                              : value
                            }
                          </TableCell>
                      )})}
                    </>
                )}
                    <TableCell className="tableCell" key={index}>
                      <Link to={`shift/${row.id}`}   style={{ textDecoration: "none" }} className="link">
                        Edit
                      </Link>
                    </TableCell>
              </TableRow>

          )})}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default List;
