import "./sidebar.scss";
import DashboardIcon from "@mui/icons-material/Dashboard";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import BackupTableIcon from '@mui/icons-material/BackupTable';
import CasinoIcon from '@mui/icons-material/Casino';
import { Link, NavLink } from "react-router-dom";
import { DarkModeContext } from "../../context/darkModeContext";
import { useContext } from "react";

const Sidebar = () => {
  const { dispatch } = useContext(DarkModeContext);
  const menus = [
    {
      path: "/",
      label: "Dashboard",
      icon: () => <DashboardIcon className="icon" />
    },
    {
      path: "/dealers",
      label: "Dealers",
      icon: () => <PersonOutlineIcon className="icon" />
    },
    {
      path: "/games",
      label: "Games",
      icon: () => <CasinoIcon className="icon" />
    },
    {
      path: "/tables",
      label: "Tables",
      icon: () => <BackupTableIcon className="icon" />
    },
  ];
  return (
    <div className="sidebar">
      <div className="top">
        <Link to="/" style={{ textDecoration: "none" }}>
          <span className="logo">DealerShift</span>
        </Link>
      </div>
      <hr />
      <div className="center">
        <ul>
          {
            menus.map((menu, i) => {
              return (
                <NavLink to={`${menu.path}`} style={{ textDecoration: "none" }} key={menu.label+i}>
                  <li key={menu.name}>
                    {menu.icon()}
                    <span>{menu.label}</span>
                  </li>
                </NavLink>
              )
            })
          }
          <p className="title">USER</p>
          <li>
            <AccountCircleOutlinedIcon className="icon" />
            <span>Profile</span>
          </li>
          <li>
            <ExitToAppIcon className="icon" />
            <span>Logout</span>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Sidebar;
