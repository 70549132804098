import Home from "./pages/home/Home";
import Login from "./pages/login/Login";
import List from "./pages/list/List";
import Single from "./pages/single/Single";
import New from "./pages/new/New";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import {gameFields, tableFields, userFields} from "./constants/formSource";
import "./style/dark.scss";
import { useContext } from "react";
import { DarkModeContext } from "./context/darkModeContext";
import SocketProvider from "./store/SocketProvider";
import React, { useEffect, useState } from 'react';
import {Provider, useDispatch, useSelector} from 'react-redux';
import store from './store/store';
import SocketContext from "../src/store/SocketContext";
import {setData} from "./store/dataSlice";
import { gameCreateVlidation, dealerCreateValidation, tabeleCreateValidation } from "./validations/SinglePage";

function App() {
    // ==========================================================================================
    // ==========================================================================================

    const [items, setItems] = useState([]);
    // const [text, setText] = useState('');


    // const data = useSelector((state) => state.data);
    // const dispatch = useDispatch();
     const socket = useContext(SocketContext)

    useEffect(() => {

        // socket.emit(`getGames`);
        //
        // socket.on(`setGames`, (initialData) => {
        //     dispatch(setData(initialData));
        // });

        // // Clean up the event listener when the component unmounts
        // return () => {
        //     socket.off(`getGames`);
        // };
        //
        // socket.on('tables', (tables) => {
        //     console.log('tables:', tables);
        //     setItems(tables);
        // });
        //
        // // Clean up the event listener
        // return () => {
        //     socket.off('games');
        // };
    }, []);


    const fetchItems = async () => {
        const response = await fetch('/api/items');
        const data = await response.json();
        setItems(data);
    };

    // const addItem = async () => {
    //     if (text) {
    //         const response = await fetch('/api/items', {
    //             method: 'POST',
    //             headers: {
    //                 'Content-Type': 'application/json'
    //             },
    //             body: JSON.stringify({ text })
    //         });
    //
    //         if (response.ok) {
    //             setText('');
    //         }
    //     }
    // };

    // const deleteItem = async (id) => {
    //     const response = await fetch(`/api/items/${id}`, {
    //         method: 'DELETE'
    //     });
    //
    //     if (response.ok) {
    //         setItems((prevItems) => prevItems.filter((item) => item.id !== id));
    //     }
    // };

    // ========================================================================================
    // ========================================================================================


    const { darkMode } = useContext(DarkModeContext);

    return (
        <div className={darkMode ? "app dark" : "app"}>
            <Provider store={store}>
            <SocketProvider>
                <BrowserRouter>
                    <Routes>
                        <Route path="/">
                            <Route index element={<Home />} />
                            <Route path="login" element={<Login />} />
                            <Route path="dealers">
                                <Route index element={<List title={'Dealers'}/>} />
                                <Route path=":itemId" element={<Single title={'Dealer'}/>} />
                                <Route
                                    path="new"
                                    element={<New fields={userFields} title="Dealer" validationSchema={dealerCreateValidation} />}
                                />
                            </Route>
                            <Route path="games">
                                <Route index element={<List title={'Games'}/>} />
                                <Route path=":itemId" element={<Single title={'Game'} />} />
                                <Route
                                    path="new"
                                    element={<New fields={gameFields} title="Game"  validationSchema={gameCreateVlidation} />}
                                />
                            </Route>
                            <Route path="tables">
                                <Route index element={<List title={'Tables'}/>} />
                                <Route path=":itemId" element={<Single title={'Table'}/>} />
                                <Route
                                    path="new"
                                    element={<New fields={tableFields} title="Table" validationSchema={tabeleCreateValidation} />}
                                />
                            </Route>
                        </Route>
                    </Routes>
                </BrowserRouter>
                </SocketProvider>
            </Provider>
        </div>
    );
}

export default App;
