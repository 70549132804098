const moment = require('moment');
export const gameColumns = [
  { field: "id", headerName: "ID", width: 70 },
  // {
  //   field: "user",
  //   headerName: "User",
  //   width: 230,
  //   renderCell: (params) => {
  //     return (
  //       <div className="cellWithImg">
  //         <img className="cellImg" src={params.row.img} alt="avatar" />
  //         {params.row.username}
  //       </div>
  //     );
  //   },
  // },
  {
    field: "name",
    headerName: "Name",
    width: 230,
  },
  {
    field: "status",
    headerName: "Status",
    width: 160,
    renderCell: (params) => {
      const status = params.row.status === 0 ? 'inactive' : 'active';
      return (
        <div className={`cellWithStatus ${status}`}>
          {status}
        </div>
      );
    },
  },
  {
    field: "createdAt",
    headerName: "Created",
    width: 250,
    renderCell: (params) => {
      const dateString = params.row.createdAt;
      const formattedDateTime = moment(dateString).format('YYYY-MM-DD HH:mm:ss');
      return (
          formattedDateTime
      );
    },
  },
  {
    field: "updatedAt",
    headerName: "Updated",
    width: 250,
    renderCell: (params) => {
      const dateString = params.row.updatedAt;
      const formattedDateTime = moment(dateString).format('YYYY-MM-DD HH:mm:ss');
      return (
          formattedDateTime
      );
    },
  },
];

//temporary data
export const DealerColumns = [
  { field: "id", headerName: "ID", width: 70 },
  // {
  //   field: "user",
  //   headerName: "User",
  //   width: 230,
  //   renderCell: (params) => {
  //     return (
  //       <div className="cellWithImg">
  //         <img className="cellImg" src={params.row.img} alt="avatar" />
  //         {params.row.username}
  //       </div>
  //     );
  //   },
  // },
  {
    field: "name",
    headerName: "Name",
    width: 230,
  },
  {
    field: "status",
    headerName: "Status",
    width: 160,
    renderCell: (params) => {
      const status = params.row.status === 0 ? 'inactive' : 'active';

      return (
          <div className={`cellWithStatus ${status}`}>
            {status}
          </div>
      );
    },
  },
  {
    field: "createdAt",
    headerName: "Created",
    width: 250,
    renderCell: (params) => {
      const dateString = params.row.createdAt;
      const formattedDateTime = moment(dateString).format('YYYY-MM-DD HH:mm:ss');
      return (
          formattedDateTime
      );
    },
  },
  {
    field: "updatedAt",
    headerName: "Updated",
    width: 250,
    renderCell: (params) => {
      const dateString = params.row.updatedAt;
      const formattedDateTime = moment(dateString).format('YYYY-MM-DD HH:mm:ss');
      return (
          formattedDateTime
      );
    },
  },
];
export const TableColumns = [
  { field: "id", headerName: "ID", width: 70 },
  // {
  //   field: "user",
  //   headerName: "User",
  //   width: 230,
  //   renderCell: (params) => {
  //     return (
  //       <div className="cellWithImg">
  //         <img className="cellImg" src={params.row.img} alt="avatar" />
  //         {params.row.username}
  //       </div>
  //     );
  //   },
  // },
  {
    field: "name",
    headerName: "Name",
    width: 230,
  },
  {
    field: "game",
    headerName: "Game",
    width: 200,
    renderCell: (params) => {
      return (
          params.row.game?.name
      );
    },
  },
  {
    field: "createdAt",
    headerName: "Created",
    width: 250,
    renderCell: (params) => {
      const dateString = params.row.createdAt;
      const formattedDateTime = moment(dateString).format('YYYY-MM-DD HH:mm:ss');
      return (
          formattedDateTime
      );
    },
  },
  {
    field: "updatedAt",
    headerName: "Updated",
    width: 250,
    renderCell: (params) => {
      const dateString = params.row.updatedAt;
      const formattedDateTime = moment(dateString).format('YYYY-MM-DD HH:mm:ss');
      return (
          formattedDateTime
      );
    },
  },
];
