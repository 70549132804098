import "./new.scss";
import React, {useContext, useEffect, useState} from 'react';
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import Grid from '@mui/material/Unstable_Grid2';
import SocketContext from "../../store/SocketContext";
import { Formik, Field, Form, ErrorMessage } from "formik";
import { Input } from '@mui/material';
import { useSelector } from "react-redux";


const New = ({ fields, title, validationSchema}) => {
    const socket = useContext(SocketContext);
    const dataStore = useSelector(store => store.data);

    const [formData, setFormData] = useState({
        game_id: '',
        name: '',
        phone: '',
        email: ''
    });
    const handleFormSubmit = async (values) => {
        socket.emit('create'+title, values);
    };

    const handleInputChange = async (event) => {
        setFormData({
            ...formData,
            [event.target.name]: event.target.value
        });
    };
    console.log("dataStore ", dataStore)
  return (
    <div className="new">
      <Sidebar />
      <div className="newContainer">
        <Navbar />
        <div className="top">
          <h1>Add New {title}</h1>
        </div>
        <div className="bottom">
          <div className="right">
            <Grid container>
              <Grid xs={6} xsOffset={3}>
                {dataStore?.error?.status && <Grid className="error-wrapper">
                  <p>{dataStore?.error?.message}</p>  
                </Grid>}
                {dataStore?.success?.status && <Grid className="success-wrapper">
                  <p>You have successfully saved</p>  
                </Grid>}
                <Formik
                  initialValues={formData}
                  validationSchema={validationSchema || {}}
                  onSubmit={handleFormSubmit}
                  enableReinitialize={true}
                >
                    {({ values, handleChange, errors, setFieldTouched, touched, isValid, handleSubmit }) => {
      
                      return (
                        <Form className="form">
                          {fields.map((field) => (
                            <div className="formInput" key={field.id}>
                              <label>{field.label}</label>
                              <Field name={field.name}>
                                  {({ field, form }) => (
                                    <>
                                      <Input
                                        {...field}
                                        value={values[field.name]}
                                        type="text"
                                        placeholder={field.label}
                                        onChange={e => form.setFieldValue(field.name, e.target.value)}
                                        className="fullWidthInput" 
                                      />
                                      <ErrorMessage component="div" className="error" name={field.name} />
                                    </>
                                  )}
                              </Field>
                            </div>
                          ))}
                          <Grid xs={12}>
                            <div align="center">
                              <button type="submit">Send</button>
                            </div>
                          </Grid>
                        </Form>
                      ) 
                    }}
                </Formik>

              </Grid>
            </Grid>

          </div>
        </div>
      </div>
    </div>
  );
};

export default New;
