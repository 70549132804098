import "./datatable.scss";
import { DataGrid } from "@mui/x-data-grid";
import { gameColumns, TableColumns, DealerColumns } from "../../datatablesource";
import {Link, useParams} from "react-router-dom";
import React, {useContext, useEffect, useState} from 'react';
import { setData, updateData } from '../../store/dataSlice';
import {useDispatch, useSelector} from 'react-redux';
import SocketContext from "../../store/SocketContext";
import PopConfirm from "./../PopConfirm/PopConfirm"

const columnMap = {
    Tables: TableColumns,
    Games: gameColumns,
    Dealers: DealerColumns,
};

const Datatable = ({title}) => {
  const columns = columnMap[title] || [];
  const data = useSelector((state) => state.data.data);
  const dispatch = useDispatch();
  const socket = useContext(SocketContext)

    useEffect( () => {
        if (socket) {
            socket.emit(`get${title}`);
            socket.on(`set${title}`, (initialData) => {
                dispatch(setData(initialData));
            });

            // Clean up the event listener when the component unmounts
            return () => {
                socket.off(`get${title}`);
            };
        }
    }, [dispatch, socket, title]);

  const handleDelete = (id) => {
    socket.emit(`delete${title}`, id)
    // Todo: implement call to backEnd with modal
      dispatch(setData(data.filter((item) => item.id !== id)));
  };

  const actionColumn = [
    {
      field: "action",
      headerName: "Action",
      width: 200,
      renderCell: (params) => {
        return (
          <div className="cellAction">
            <Link to={`${params.row.id}`}   style={{ textDecoration: "none" }}>
              <div className="viewButton">View</div>
            </Link>
            <PopConfirm onConfirm={() => handleDelete(params.row.id)} title="Are you shure?">
              Delete
            </PopConfirm>
          </div>
        );
      },
    },
  ];

  return (
    <div className="datatable">
      <div className="datatableTitle">
        {title}
        <Link to="new" className="link">
          Add New
        </Link>
      </div>
      <DataGrid
        className="datagrid"
        rows={Array.isArray(data) ? data : []}
        columns={columns.concat(actionColumn)}
        pageSize={9}
        rowsPerPageOptions={[9]}
      />
    </div>
  );
};

export default Datatable;
