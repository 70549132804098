import * as Yup from 'yup';

export const gameCreateVlidation = Yup.object({
    name: Yup.string().required()
});

export const dealerCreateValidation = Yup.object({
    name: Yup.string().required(),
    email: Yup.string().required().email(),
    phone: Yup.string().required()
});

export const tabeleCreateValidation = Yup.object({
    name: Yup.string().required(),
    game_id: Yup.number()
    .positive()
    .required()
})