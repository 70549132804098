export const userFields = [
    {
      id: 1,
      label: "Name",
      type: "text",
      name:"name"
      // placeholder: "Name",
    },
    {
      id: 2,
      label: "Email",
      type: "mail",
      name:"email"

      // placeholder: "john_doe@gmail.com",
    },
    {
      id: 3,
      label: "Phone",
      type: "text",
      name:"phone"

      // placeholder: "+374 93 11 11 11",
    }
  ];
  export const gameFields = [
    {
      id: 1,
      label: "Name",
      type: "text",
      name:"name"

      // placeholder: "Game name",
    }
  ];
  export const tableFields = [
    {
      id: 1,
      label: "Name",
      type: "text",
      name:"name"

      // placeholder: "Table Name",
    },
    {
      id: 2,
      label: "Game ID",
      type: "text",
      name:"game_id"
      // placeholder: "Game",
    }
  ];
  